<template lang="pug">
// Work
section.grid.imprint.spaced
  article.title.underlined #[.number 01] #[.text Imressum]
  article.content
    .grid
      div TEST
</template>

<script>
import Logo from '@/components/Logo.vue';

export default {
  name: 'Imprint',
  components: {
    Logo,
  }
}
</script>
