<template lang="pug">
.logo(:style="fontsize ? `font-size: ${fontsize};` : ''")
  .line(v-for="i in Array(4)" :key="i")
  .inner
    .bachschmid bachschmid
    br
    .media
      .dot #[span .]
      |media
</template>

<script>
export default {
  name: 'Logo',
  props: {
    fontsize: {
      type: String,
      required: false
    }
  }
}
</script>
