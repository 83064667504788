<template lang="pug">
transition(name="fade")
  Intro(v-if="intro")
transition(name="fade")
  AppBody(
    v-if="!intro"
    :updateUrlAndMeta="updateUrlAndMeta"
    :scrollToElementWithId="scrollToElementWithId"
    :currentPath="currentPath"
  )
</template>

<script>
import AppBody from "@/components/AppBody.vue"; // @ is an alias to /src
import Intro from "@/components/Intro.vue"; // @ is an alias to /src

export default {
  name: "App",

  components: {
    AppBody,
    Intro,
  },

  data: function () {
    return {
      intro: false,
      currentPath: this.getUrl(true),
    };
  },

  created () {
    // Check cookie
    // this.$cookie.set("keyName", 'jo', new Date())
    if (!this.$cookie.getCookie("intro")) {
      this.startIntro();
      this.$cookie.setCookie("intro", true, { expire: 60 * 5 });
      setTimeout(() => {
        this.endIntro();
      }, 2600);
    } else {
      this.endIntro();
    }

    window.addEventListener("scroll", this.onscroll, false);
    window.addEventListener("resize", this.onresize);

    window.$.fn.isInViewport = function () {
      var $ = window.$;
      if (this.length) {
        var elementTop = $(this).offset().top;
        var elementBottom = elementTop + $(this).outerHeight();

        var viewportTop = $(window).scrollTop();
        var viewportBottom = viewportTop + $(window).height();

        var windowHeight = window.innerHeight;

        return (
          elementBottom > viewportTop + windowHeight * 0.65 &&
          elementTop < viewportBottom - windowHeight * 0.65
        );
      }
    };
  },

  mounted () {
    setTimeout(() => {
      this.fixAppHeight();
    }, 1);


    this.$nextTick(() => {
      this.$router.afterEach((to, from) => {
        this.$nextTick(() => {
          const id = this.fixChars(to.path);
          const scrollBehavior =
            from?.matched[0]?.name !== to?.matched[0]?.name
              ? "instant"
              : "smooth";
          this.currentPath = this.getUrl(true);
          this.updateUrlAndMeta();
          this.scrollToElementWithId(id, scrollBehavior);
          this.setLogoColor();
        });
      });
    });
  },

  methods: {
    startIntro() {
      window.$("html, body").addClass("intro-running");
      this.intro = true;
    },

    endIntro() {
      window.$("html, body").removeClass("intro-running");
      this.intro = false;
    },

    setLogoColor: (color) => {
      window.$("header .logo .media").css("color", color ?? "");
    },

    scrollToElementWithId(id, behavior) {
      const $ = window.$;
      const header = document.getElementsByTagName("header");
      const yOffset = header[0] ? header[0].clientHeight : 0;
      let element = $(`[path="${id}"]`).get(0);

      // Search for alternative paths ...
      if (!element) {
        element = $("[paths]")
          .filter((i, e) => $(e).attr("paths").split(" ").includes(id))
          .get(0);
      }

      if (element) {
        const top =
          element.getBoundingClientRect().top + window.pageYOffset - yOffset;
        window.scrollTo({ top, behavior });
      }
    },

    updateUrlAndMeta() {
      const path = this.currentPath;
      const currentRoute = this.$router.getRoutes().find((e) => e.path == path);
      const title = currentRoute?.meta?.transTitle || "";

      if (title) {
        document.title = this.$t(title, {
          wrapper: this.$t("meta.title.wrapper"),
        });
      }

      history.replaceState({}, title, path);
    },

    getUrl: function (fix = false) {
      const url = window.location.pathname ?? '';
      return fix ? this.fixChars(url) : url;
    },

    fixChars(str) {
      return str.replaceAll("%C3%BC", "ü");
    },

    fixAppHeight() {
      const wh = window.innerHeight;
      const doc = document.documentElement;
      const vh = wh * 0.01;

      doc.style.setProperty("--app-height", `${wh}px`);
      doc.style.setProperty("--vh", `${vh}px`);
    },

    onresize() {
      this.fixAppHeight();
    },

    onscroll() {
      this.fixAppHeight();

      window.clearTimeout(this.isScrolling);
      this.isScrolling = setTimeout(() => {
        setTimeout(() => this.fixAppHeight(), 200);
      }, 100);

      this.checkScrollPosition();
    },

    checkScrollPosition() {
      const url = this.getUrl(true);

      if (!window.$(`[path=\\${url}]`).isInViewport()) {
        let elements = [];

        window.$("[path]").each(function (index, element) {
          const marginTop = parseInt(window.$(element).css("margin-top"));
          const height = element.getBoundingClientRect().top - marginTop;
          const color = element.getAttribute("data-logo-color");

          if (height < 0) {
            elements.push({ element, height, color });
          }
        });

        var curr = elements.sort((a, b) => b.height - a.height)[0];

        if (curr) {
          const id = curr.element.getAttribute("path");
          const color = curr.color;

          if (id != url) {
            this.currentPath = id;
            this.updateUrlAndMeta(id);
            this.setLogoColor(color);
          }
        }
      }
    },
  },

  watch: {
    "$i18n.locale": function () {
      this.updateUrlAndMeta();
    },
  },
};
</script>
