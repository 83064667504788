<template lang="pug">
.intro-wrapper
  Logo.big.intro
</template>

<script>
import Logo from "@/components/Logo.vue"; // @ is an alias to /src

export default {
  components: {
    Logo
  }
}
</script>
