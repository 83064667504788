<template lang="pug">
.mobile-menu(
  :class="{ open: open }"
)
  .logo-wrapper(data-aos="fade-in")
    Logo
  .closer-wrapper(data-aos="fade-in")
    button.closer(@click="$emit('close_menu')")
      .x
      .x
  Contact(
    style="text-align: left"
    data-aos="fade-in"
    data-aos-delay="200"
    data-aos-duration="800"
  )
  LocaleChanger(
    data-aos="fade-in"
    data-aos-delay="200"
    data-aos-duration="800"
  )
  Navigation(
    :currentPath="currentPath"
    @close_menu="$emit('close_menu')"
    :aos="{duration: 800, delay: 100}"
  )
</template>

<script>
import Navigation from "@/components/Navigation.vue"; // @ is an alias to /src
import Logo from "@/components/Logo.vue"; // @ is an alias to /src
import LocaleChanger from "@/components/LocaleChanger.vue"; // @ is an alias to /src
import Contact from "@/components/Contact.vue"; // @ is an alias to /src

export default {
  name: "MobileNav",

  props: {
    currentPath: {
      type: String,
      required: true,
    }
  },

  components: {
    Navigation,
    Logo,
    LocaleChanger,
    Contact,
  },

  data: function () {
    return {
      open: false
    };
  },

  mounted: function () {
    setTimeout(() => { this.open = true }, 200);
  },

}
</script>
