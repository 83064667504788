<template lang="pug">
// Home
section.grid.home(
  :path="$t('router.home')"
  data-logo-color="inherit"
)
  transition-group(name="fade-up")
    article.text.work(v-if="mounted" :style="{transitionDelay: '100ms'}")
      | Konzeption, Design Und Programmierung von individuellen Web-Projekten
    Contact(v-if="mounted" :style="{transitionDelay: '400ms'}")
    article.text.one(v-if="mounted" :style="{transitionDelay: '600ms'}")
      | Shop
      br
      | Webdesign
      br
      | E-commerce
    
  transition(name="fade-left")
    article.text.two(
      v-if="mounted"
      :style="{transitionDelay: '900ms'}"
    )
      //- span.inline Linux
      //- span.inline server
      span.inline Java
      span.inline Script
      br
      //- | Backups
      | Python
      br
      //- | Email
      | PHP
  transition(name="fade-right")
    article.logo-wrapper(
      v-if="mounted"
      :style="{transitionDelay: '900ms'}"
    )
      Logo.big

// Work
section.grid.work.spaced(
  :path="$t('router.work')"
  paths="/arbeit /work"
  data-logo-color="#66c4e9"
)
  article.title.grid(
    data-aos="fade-up"
    data-aos-duration="1000"
  ) #[.number 01] #[.text Arbeit]
  article.content
    .work-entry.grid(
      v-for="work in works"
      data-aos="fade-up"
      data-aos-duration="1000"
    )
      .title {{ work.title }}
      .text {{ work.text }}
      .avatar
        component(:is="work.avatar.component")

// About
section.grid.about.spaced(
  :path="$t('router.about')"
  paths="/about-me /%C3%BCber-mich"
  data-logo-color="#f9b87f"
)
  article.title.grid #[.number 02] #[.text About me]
  article.content About Me!!!
    p(v-for="i in Array(10)") -
    #test Test
    h1 #[Logo.big]
    p(v-for="i in Array(100)") -
</template>

<script>
import Logo from '@/components/Logo.vue';
import Contact from '@/components/Contact.vue';

// Forms
import Circles from '@/components/Forms/Circles.vue';
import Triangles from '@/components/Forms/Triangles.vue';
import Squares from '@/components/Forms/Squares.vue';


export default {
  name: 'Home',
  components: {
    Logo,
    Contact,
  },
  data () {
    return {
      mounted: false,
      works: [
        {
          title: "Programmierung von Plugins & Modulen",
          text: `Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum`,
          avatar: {
            component: Squares,
          }
        },
        {
          title: "Etwas Anderes",
          text: `Lorem I Lorem I psum Lorem I Lorem I psum Psum irum Lorem I Lorem I psum Lorem I Lorem I psum Psum irumLorem I Lorem I psum Lorem I Lorem I psum Psum irum Lorem I Lorem I psum Lorem I Lorem I psum Psum irum`,
          avatar: {
            component: Circles,
          }
        },
        {
          title: "Programmierung von Plugins & Modulen",
          text: `Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum`,
          avatar: {
            component: Triangles,
          }
        },
        {
          title: "Programmierung von Plugins & Modulen",
          text: `Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum`,
          avatar: {
            component: Squares,
          }
        },
      ]
    }
  },
  mounted() {
    this.mounted = true
  }
}
</script>
