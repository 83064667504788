<template lang="pug">
main
  router-view
</template>

<script>
export default {
  name: 'AppMain',

  props: {
    scrollToElementWithId: {
      type: Function,
      required: true,
    },
    currentPath: {
      type: String,
      required: true
    }
  },

  mounted: function () {
    setTimeout(() => {
      this.scrollToElementWithId(this.currentPath, "instant");
    }, 0);
  },
}
</script>
