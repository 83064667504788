<template lang="pug">
header.grid
  .logo-wrapper
    router-link(:to="$t('router.home')")
      Logo.animatable.intro
  .nav-opener(@click="$emit('open_menu')")
    button
      .l(v-for="i in Array(3)")
  Navigation(
    :currentPath="currentPath"
  )
</template>
<script>
import Navigation from "@/components/Navigation.vue"; // @ is an alias to /src
import Logo from "@/components/Logo.vue"; // @ is an alias to /src

export default {
  name: "Header",

  props: {
    currentPath: {
      type: String,
      required: true,
    }
  },

  components: {
    Navigation,
    Logo,
  },
}
</script>