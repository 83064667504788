import { createApp } from 'vue'
import App from '@/App.vue'
import router from '@/router'
import i18n from '@/i18n'

// Vue Clipboard
import { VueClipboard } from '@soerenmartius/vue3-clipboard'

// Add Vue Cookie next
import { VueCookieNext } from 'vue-cookie-next'

// importing AOS
import aos from '@/plugins/aos'

// Vue Meta
import { createMetaManager } from 'vue-meta'

// SCSS
import './assets/css/reboot.css'
import './assets/scss/neue-haas-groteskt-display-pro-font.scss'
import './assets/scss/animations.scss'
import './assets/scss/base.scss'
import './assets/scss/responsive.scss'

// Add jQuery
import $ from 'jquery-slim/dist/jquery.slim.min.js'
window.$ = $

// Create App
createApp(App)
  .use(i18n)
  .use(router)
  .use(VueClipboard)
  .use(VueCookieNext)
  .use(createMetaManager())
  .use(aos)
  .mount('#app')
