<template lang="pug">
.locale-changer
  .inner
    .locale(v-for="lang in langs")
      a.link(
        v-if="showall || !isActive(lang)"
        href="#"
        @click="change(lang)"
        :active="isActive(lang)"
      )
        span {{ getName(lang) }}
  //- select(v-model="$i18n.locale")
  //- option(v-for="(lang, i) in langs", :key="`Lang${i}`", :value="lang") {{ lang }}
</template>

<script>
export default {
  name: "locale-changer",

  props: {
    shortnames: {
      type: Boolean,
      default: false,
    },
    showall: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      langs: [
        {
          title: "Deutsch",
          code: "de",
        },
        {
          title: "Englisch",
          code: "en",
        },
      ],
    };
  },

  methods: {
    change: function (lang) {
      const scrollY = document.documentElement.scrollTop
      setTimeout(() => window.scroll(0,scrollY), 0);

      let pathname = window.location.pathname
      pathname = this.$t(pathname)
      history.pushState(null, '', pathname);

      this.$i18n.locale = lang.code
      this.$cookie.setCookie('locale', lang.code)
    },

    isActive: function (lang) {
      return lang.code === this.$i18n.locale
    },

    getName: function (lang) {
      let name = ''
      let l;

      if (!this.showall) {
        l = this.langs.find(o => o.code === this.$i18n.locale)
      } else {
        l = lang
      }

      if (this.shortnames) {
        name = l ? l.code.toUpperCase() : ''
      } else {
        name = l ? l.title : ''
      }

      return name
    }
  }
}
</script>