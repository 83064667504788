import Home from '@/views/Home.vue'
import Imprint from '@/views/Imprint.vue'

// console.log(i18n.t('hallo'))
// import i18n from '@/i18n'
// const { t } = i18n.global


export default [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      transTitle: `meta.title.home`,
    },
    children: [
      {
        path: 'arbeit',
        alias: ['arbeit', 'work'],
        component: Home,
        meta: {
          transTitle: `meta.title.work`,
        },
      },
      {
        path: 'about',
        alias: ['ueber-mich', '\u00fcber-mich', '%C3%BCber-mich', 'about-me', 'about' ],
        component: Home,
        meta: {
          transTitle: `meta.title.about`,
        },
      },
      {
        path: 'referenzen',
        alias: ['referenzen', 'credentials'],
        component: Home,
        meta: {
          transTitle: `meta.title.credentials`,
        },
      },
      {
        path: 'contact',
        alias: ['contact', 'kontakt'],
        meta: {
          transTitle: `meta.title.contact`,
        },
      }
    ]
  },
  {
    path: '/impressum',
    name: 'Impressum',
    component: Imprint,
    alias: ['/imprint', '/impressum'],
    meta: {
      transTitle: `meta.title.imprint`,
      metaTags: [
        {
          name: 'description',
          content: 'The home page of our example app.'
        },
        {
          property: 'og:description',
          content: 'The home page of our example app.'
        }
      ]
    }
  }
  // {
  //   path: '/test',
  //   name: 'Home',
  //   component: Home,
  // },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]