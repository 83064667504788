import { createI18n } from 'vue-i18n/index'
import { VueCookieNext } from 'vue-cookie-next'

/**
 * Load locale messages
 *
 * The loaded `JSON` locale messages is pre-compiled by `@intlify/vue-i18n-loader`, which is integrated into `vue-cli-plugin-i18n`.
 * See: https://github.com/intlify/vue-i18n-loader#rocket-i18n-resource-pre-compilation
 */
function loadLocaleMessages() {
  const locales = require.context('@/i18n/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

/**
 * Return the current locale. For example "en" or "de"
 *
 * return String
 */
function getLocale() {
  return VueCookieNext?.getCookie('locale') ||
          navigator.language?.substr(0, 2) || 
          process.env.VUE_APP_I18N_LOCALE || 
          'en'
}

/**
 * Return the fallback-locale.
 *
 * return String
 */
function getLocaleFallback () {
  return process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en'
}

export default createI18n({
  legacy: false,
  locale: getLocale(),
  globalInjection: true,
  fallbackLocale: getLocaleFallback(),
  messages: loadLocaleMessages()
})
