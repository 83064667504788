<template lang="pug">
nav.grid
  template(v-for="(link, i) in links", :key="i")
    transition(name="fade-up")
      .link_wrapper(v-if="mounted", :class="link.class", :style="linkStyle(i)")
        router-link.link(
          :to="resolvePath(link)",
          @click="$emit('close_menu')",
          :active="isActive(link)"
        )
          span {{ $t(`nav.${link.id}`) }}
        LocaleChanger(
          v-if="link.with_contact",
          :shortnames="true",
          :showall="false",
          :title="$t('lang.change')"
        )
</template>

<script>
import LocaleChanger from "@/components/LocaleChanger.vue";

export default {
  name: "Navigation",

  props: {
    aos: {
      type: Object,
      default: null,
    },
    currentPath: {
      type: String,
      required: true,
    },
  },

  components: {
    LocaleChanger,
  },

  data: function () {
    return {
      mounted: false,
      links: [
        {
          id: "work",
          alternatives: "/work /arbeit",
        },
        {
          id: "about",
          alternatives: "/about /über-mich /%C3%BCber-mich /about-me",
        },
        {
          id: "cred",
          alternatives: "/referenzen /credentials /cred",
        },
        {
          id: "contact",
          alternatives: "/contact /kontakt",
          with_contact: true,
        },
      ],
    };
  },

  computed: {
    window: () => window,
  },

  mounted() {
    setTimeout(() => {
      this.mounted = true;
    }, 100);
  },

  methods: {
    resolvePath(link) {
      return this.$t(`router.${link.id}`);
    },
    isActive(link) {
      return link?.alternatives?.split(" ")?.includes(this.currentPath);
    },
    linkStyle(i) {
      return {
        transitionDelay: (i + 1) * (this.aos?.delay ?? 200) + "ms",
        transitionDuration: this.aos?.duration ?? 800,
      };
    },
  },
};
</script>

