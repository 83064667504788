<template lang="pug">
Header(
  @open_menu="open_menu"
  :currentPath="currentPath"
)
transition-group(name="fade_app_main")
  AppMain(
    v-if="animated"
    :currentPath="currentPath"
    :scrollToElementWithId="scrollToElementWithId"
  )
  Footer(v-if="animated")
transition(name="slide-top")
  MobileMenu(
    v-if="mobile_menu_open",
    :currentPath="currentPath",
    @close_menu="close_menu"
  )
</template>


<script>
import Header from "@/components/Header.vue"; // @ is an alias to /src
import Footer from "@/components/Footer.vue"; // @ is an alias to /src
import MobileMenu from "@/components/MobileMenu.vue"; // @ is an alias to /src
import Logo from "@/components/Logo.vue"; // @ is an alias to /src
import AppMain from "@/components/AppMain.vue"; // @ is an alias to /src

export default {
  name: "AppBody",

  props: {
    updateUrlAndMeta: {
      type: Function,
      required: true,
    },
    scrollToElementWithId: {
      type: Function,
      required: true,
    },
    currentPath: {
      type: String,
      required: true
    }
  },

  components: {
    Header,
    Footer,
    MobileMenu,
    Logo,
    AppMain,
  },

  data: function () {
    return {
      animated: false,
      isScrolling: 0,
      mobile_menu_open: false,
    };
  },

  computed: {
    body: () => document.body,
  },

  mounted: function () {
    this.updateUrlAndMeta();

    setTimeout(() => {
      this.animated = true
    }, 1000);
  },

  methods: {
    open_menu: function () {
      this.trigger_menu(true);
    },

    close_menu: function () {
      this.trigger_menu(false);
    },

    trigger_menu: function (state) {
      this.mobile_menu_open = state;
      let classes = "mobile-menu-open";

      if (this.mobile_menu_open) {
        this.body.classList.add(classes);
        //   this.body.style.position = 'fixed';
        //   this.body.style.top = `-${window.scrollY}px`;
      } else {
        this.body.classList.remove(classes);
        //   this.body.style.position = '';
        //   this.body.style.top = '';
      }
    },
  },


};
</script>

