<template lang="pug">
footer
  router-link(:to="$t('router.imprint')") {{ $t('nav.imprint') }}
  //- Logo
</template>

<script>
import { defineComponent } from 'vue';
// import Logo from '@/components/Logo.vue';

export default defineComponent({
  name: 'Home',
  // components: {
  //   Logo,
  // }
});
</script>
