<template lang="pug">
article.contact
  p
    a.inline(
      :href="'tel:' + phone"
      target="_blank"
      v-html="$t('addr.phone')"
    )
    br
    a.email(
      :title="$t('copy.mail', { mail })"
      v-clipboard:copy="mail"
      v-clipboard:success="copy_success"
      v-clipboard:error="copy_error"
    )
      template(v-if="copied")
        | {{ $t('copied.mail', { mail }) }}
      template(v-else-if="error")
        | {{ $t('copy.error') }}
      template(v-else)
        | {{ $t('copy.mail', { mail: $t('mail') }) }}
  p
    | {{ $t('addr.street')}}
    br
    | 86399 Bobingen
    br
    | {{ $t('addr.country')}}
  p
    a(href="//wa.me/+4982349687444", target="_blank") WhatsApp
    br
    a(href="//twitter.com/bachschmidmedia", target="_blank") Twitter
    br
    a(href="//instagram.com/bachschmidmedia", target="_blank") Instagram
</template>

<script>
export default {
  name: 'Contact',

  data: function() {
    return {
      mail: process.env.VUE_APP_MAIL,
      phone: process.env.VUE_APP_PHONE,
      copied: false,
      error: false,
    }
  },

  methods: {

    copy_success: function () {
      this.copied = true
      this.reset()
    },
    copy_error: function () {
      this.error = true
      this.reset()
    },

    reset: function () {
      setTimeout(() => {
        this.copied = false,
        this.error = false
      }, 1 * 1000);
    }
  }
}
</script>
